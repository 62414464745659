import React, {useState, useContext, useEffect} from "react";
import ReactQuill from 'react-quill';
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { Form, Field } from '../../../../components/Form';
import { Redirect } from 'react-router-dom';
import Alert from "../../../../components/Alert";


const AddFocus = () => {
    const title = 'Add Focus';

    const { userData } = useContext(AuthContext);

    const [focus, setFocus] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const [inSubmit, setInSubmit] = useState(false);

    const [redirect, setRedirect] = useState(null);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb, title]);


    return (
        <>
            {redirect === null && 
            <>
                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="card">
                            <div className="card-header">
                                {title}
                            </div>
                            {errorMessage !== null && 
                                <Alert type="danger" message={errorMessage} dismissible={true} onDismiss={() => setErrorMessage(null)}></Alert>
                            }
                            <div className="card-body">
                                <Form handleSubmit={e =>{
                                    e.preventDefault();
                                    setInSubmit(true);
                                    setErrorMessage(null);
                                    const createFocus = CloudFunctions.httpsCallable('createFocus');
                                    createFocus({
                                        clientId: userData.currentClient.id,
                                        focus: focus,
                                        created: Date.now()
                                    }).then(response => {
                                        setRedirect('/client/'+userData.currentClient.id);
                                    }).catch(err => {
                                        setErrorMessage(err.message);
                                        setInSubmit(false);
                                    })
                                }}
                                disabled={inSubmit}
                                inSubmit={inSubmit}
                                enableDefaultButtons={true}>
                                    <Field label="Focus">
                                    <ReactQuill 
                                        theme='snow'
                                        onChange={setFocus}
                                        value={focus}
                                        placeholder='A mindfullness focus point'
                                    />
                                    </Field>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            {redirect !== null &&
                <Redirect to={redirect}></Redirect>
            }
        </>

    )
}

export default AddFocus;
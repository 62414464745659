import React, { useContext, useState, useEffect, useRef } from "react";
import ReactQuill from 'react-quill';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { useHistory } from 'react-router'

const GoalList = () => {
    const title = "Monthly Mindfullness Points"
    const { userData, authUser } = useContext(AuthContext);

    // document snapshots
    const pageSize = 10;
    const [qs, setQs] = useState(null);
    const mountedRef = useRef(true);

    const [rows, setRows] = useState([]);
    const [toEnd, setToEnd] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const getNotes = (clientId, pageSize, lastDoc) => {
        setLoading(true);
        let records = [];
        const collectionRef = FirebaseAuth.firestore().collection('clients').doc(clientId).collection('focus');
        let query = collectionRef.orderBy('created', 'desc');
        if(lastDoc){
            query = query.startAfter(lastDoc);
        }
        query = query.limit(pageSize);
        query.get().then(documentSnapshots => {
            if (!mountedRef.current) return null
            if(documentSnapshots.empty){
                setToEnd(true);
            }else{
                documentSnapshots.forEach(doc => {
                    records.push({
                        'id': doc.id,
                        'focus': doc.data().focus
                    });
                });
                if(records.length > 0){
                    setRows(rows => rows.concat(records));
                    setQs(documentSnapshots);
                }
            }
            setLoading(false);
        });
    };
    useEffect(() => {
        getNotes(userData.currentClient.id, pageSize);
        return () => { 
            mountedRef.current = false
        }
    },[userData]);
    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="text-right mb-3">
                        {userData.currentClient.owner === authUser.user.uid &&
                            <>
                                <Link to={"/client/"+userData.currentClient.id+"/focus/add"} className="btn btn-primary"><i className="fa fa-plus"></i> Add Mindfullness Point</Link>
                            </>
                        }
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {title}
                        </div>
                        <div className="card-body container">
                            {rows.length > 0 ?
                                <>
                                    {rows.map((r,i) => 
                                        <div className="row" key={r.id}>
                                            <ReactQuill
                                                value={r.focus}
                                                readOnly={true}
                                                theme={"bubble"}
                                                className="col-9"
                                            />
                                            <div className="text-right mb-3 col-3">
                                                {userData.currentClient.owner === authUser.user.uid &&
                                                    <>
                                                        <button className="btn btn-primary"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            const deleteFocus = CloudFunctions.httpsCallable('deleteFocus');
                                                            deleteFocus({
                                                                clientId: userData.currentClient.id,
                                                                focusId: r.id
                                                            }).then(res => {
                                                                history.go(0);
                                                                //setInSubmit(false);
                                                                //setSuccess(true);
                                                                //to={"/client/"+userData.currentClient.id+"/notes/add"}
                                                            }).catch(err => {
                                                                // setInSubmit(false);
                                                                // if(err.details && err.details.code === 'auth/user-not-found'){
                                                                //     setInviteDialog(true);
                                                                //     setInSubmit(false);
                                                                // }else{
                                                                //     setError(err.message);
                                                                // }
                                                            });
                                                    }}><i className="fa fa-minus"></i> Delete</button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </>
                            : (!loading && "No mindfullness points currently set")}
                            {loading?(
                                <Loader text="Loading data..."></Loader>
                            ):(
                                <>
                                
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GoalList;
import React, { useContext, useEffect, useRef} from "react";
import { BreadcrumbContext } from '../../../components/Breadcrumb';
import {  Redirect } from 'react-router-dom';
import { AuthContext } from "../../../components/FirebaseAuth";

const Home = () => {
    const title = 'Dashboard';

    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const { userData } = useContext(AuthContext);
    
    const mountedRef = useRef(true);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        return () => { 
            mountedRef.current = false
        }
    },[setBreadcrumb]);

    return (
        <>
        {
            userData.clientId ?
            <Redirect to={'/client/'+userData.clientId}></Redirect>
            :
            
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="card">
                        <div className="card-header">
                            {title}
                        </div>
                        <div className="card-body">
                            <h2>Welcome!</h2>
                        </div>
                    </div>
                </div>
            </div>
        
        }
        </>
    )
}

export default Home;
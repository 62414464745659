import React, {useState, useContext, useEffect, useRef} from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { FirebaseAuth, CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { Link, Redirect } from 'react-router-dom';
import Loader from '../../../../components/Loader';

const Home = () => {
    const title = 'Clients';

    const { setBreadcrumb } = useContext(BreadcrumbContext);
    

    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const mountedRef = useRef(true);

    const getAccounts = () => {
        setLoading(true);
        let records = [];
        const accountsRef = FirebaseAuth.firestore().collection('clients');
        let query = accountsRef.where('access', 'array-contains', FirebaseAuth.auth().currentUser.uid).orderBy('firstName', 'asc');
        query.get().then(accountSnapshots => {
            if (!mountedRef.current) return null
            accountSnapshots.forEach(account => {
                records.push({
                    'id': account.id,
                    'firstName': account.data().firstName,
                    'lastName': account.data().lastName,
                    'email': account.data().email,
                    'status': account.data().status,
                    'created': account.data().creationTime,
                    'owner': account.data().owner,
                    'subscriptionStatus': account.data().subscriptionStatus,
                    'subscriptionCreated': (new Date(account.data().subscriptionCreated * 1000)).toLocaleString()
                });
            });
            setAccounts(records);
            setLoading(false);
        });
    }

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        getAccounts();
        return () => { 
            mountedRef.current = false
        }
    },[setBreadcrumb]);

    const sendInvite = async(clientId, ownerId, name, email) => {
        const inviteUserToClient = CloudFunctions.httpsCallable('inviteUserToClient');
        inviteUserToClient({
            clientId: clientId,
            ownerId: ownerId,
            name: name,
            email: email
        }).then(res => {
            // physical page load to reload the account data
        }).catch(err => {
        });
    }

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    
                    {accounts.length > 0 ? (
                        <>
                            <div className="text-right mb-3">
                                <Link to="/new-client" className="btn btn-primary"><i className="fa fa-plus"></i> Invite Client</Link>
                            </div>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {accounts.map((account, i) => 
                                    <tr key={account.id}>
                                        <th scope="row"><a href={'/client/'+account.id+'/'}>{account.firstName +' '+account.lastName}</a></th>
                                        <td>{account.email}</td>
                                        <td>{account.status === "Accepted" ? account.subscriptionStatus :
                                            account.status}</td>
                                        <td>{account.status === "Invited" ?
                                            <button className="btn btn-block btn-primary" onClick={() => 
                                                sendInvite(account.id, account.owner, account.firstName +' '+account.lastName, account.email)}>Resend Invite
                                            </button> : <></>
                                            }
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <>
                            {(loading) ? (
                                <Loader text="loading clients..."></Loader>
                            ):(
                                <Redirect to="/new-client"></Redirect>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>

    )
}

export default Home;
import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "./icon";

const Logo = () => {
    return (
        <>
            <NavLink className="c-sidebar-brand-full" to="/">
                <h5 className="text-light c-sidebar-brand-full">
                    <Icon className="fa c-sidebar-brand-full mr-2 sidebar-logo"></Icon>
                    Strong Balanced Solutions
                </h5>
            </NavLink>
            <NavLink className="c-sidebar-brand-minimized" to="/">
            <h5 className="c-sidebar-brand-minimized">
                <Icon className="fa c-sidebar-brand-minimized sidebar-logo"></Icon>
            </h5>
            </NavLink>
        </>
    )
}

export default Logo;
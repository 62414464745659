import * as React from "react"

const Icon = ({className}) => {
  return (
    <svg className={className} viewBox="0 0 139.7 124.7">
      <defs>
        <style>{".cls-2{fill:#d78330}"}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M130.92 64.93c3 0 5.69-2.48 8.78-.71v7c-3.74-4.62-5.53-4.78-8.1.36-3.43 6.85-9.68 11.26-14.23 17.13-2.2 2.83-4.85 5.76-6.86 8.81C103 109 92.23 116.63 80.57 123.14c-5 2.81-9.41 1.58-13.84-1.84-4.13-3.19-6.48-7.88-10.53-11.13a41 41 0 00-7-4.57c-4.37-2.25-8.1-5.14-10.22-9.77a15.81 15.81 0 00-4.2-5.47c-6.81-5.83-10.38-5.45-14.82 2.54-5 9-11.75 16.91-16 26.38-.67 1.49-1.44 3.58-4 2.84v-4c6.83-8.28 11.31-17.95 16.24-27.35 1.57-1.07 3.21-2.06 3.78-4 .73-.38 1.72-.57 2.13-1.17 4-5.92 8.36-3 12.79-.63a12.7 12.7 0 015 5.32 31.45 31.45 0 0012.38 12.54 23.84 23.84 0 019.38 8.66 15.25 15.25 0 006.75 5.94c3 3.66 7.22 3.52 10.38 1.64 8.09-4.81 17.35-9.34 22.43-16.9 9-13.32 21.26-23.73 29.7-37.24z"
            fill="#146760"
          />
          <path
            className="cls-2"
            d="M130.92 64.93c-8.44 13.51-20.7 23.92-29.63 37.2C96.21 109.69 87 114.22 78.86 119c-3.16 1.88-7.41 2-10.38-1.64a38.31 38.31 0 0011.2-2.14c12.11-4.74 19.65-17.5 17.57-29.83-2.39-14.12-13.16-23.5-27.6-24C56.13 60.89 46 53 42.58 40.43c-3.26-12.12 1.7-24 13-31.24.64-.41 1.53-.63 1.54-1.65C25.65 12.3 1.68 52 20 86.73c-.57 2-2.21 3-3.78 4C.67 64.81 7.65 22.57 44.67 5.56c41.5-19.08 87.05 13.72 86.14 56.38-.02 1 .07 1.99.11 2.99zM69.63 23.27a9 9 0 00-8.79 8.94 9.16 9.16 0 008.58 8.73 9.23 9.23 0 009.08-8.78 9 9 0 00-8.87-8.89z"
          />
          <path
            className="cls-2"
            d="M69.52 99.45a8.76 8.76 0 010-17.52 9 9 0 019.11 9 8.75 8.75 0 01-9.11 8.52z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Icon
import React, {useState, useContext, useEffect} from "react";
import ReactQuill from 'react-quill';
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { Form, Field, Input, TextArea } from '../../../../components/Form';
import { Redirect } from 'react-router-dom';
import Alert from "../../../../components/Alert";


const AddNote = () => {
    const title = 'Add Session Note';

    const { userData } = useContext(AuthContext);

    const [sessionDate, setSessionDate] = useState({
        hasError: false,
        error: null,
        value: null
    });
    const [sessionNotes, setSessionNotes] = useState('');
    const [sessionNumber, setSessionNumber] = useState({
        hasError: false,
        error: null,
        value: null
    });
    const [errorMessage, setErrorMessage] = useState(null);

    const [inSubmit, setInSubmit] = useState(false);

    const [redirect, setRedirect] = useState(null);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb, title]);


    return (
        <>
            {redirect === null && 
            <>
                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="card">
                            <div className="card-header">
                                {title}
                            </div>
                            {errorMessage !== null && 
                                <Alert type="danger" message={errorMessage} dismissible={true} onDismiss={() => setErrorMessage(null)}></Alert>
                            }
                            <div className="card-body">
                                <Form handleSubmit={e =>{
                                    e.preventDefault();
                                    setInSubmit(true);
                                    setErrorMessage(null);
                                    const createNote = CloudFunctions.httpsCallable('createNote');
                                    createNote({
                                        clientId: userData.currentClient.id,
                                        sessionDate: sessionDate.value,
                                        sessionNumber: sessionNumber.value,
                                        sessionNotes: sessionNotes,
                                        created: Date.now()
                                    }).then(response => {
                                        const noteId = response.data.noteId;
                                        setRedirect('/client/'+userData.currentClient.id+'/notes/');
                                    }).catch(err => {
                                        setErrorMessage(err.message);
                                        setInSubmit(false);
                                    })
                                }}
                                disabled={sessionDate.hasError || sessionDate.value===null || inSubmit}
                                inSubmit={inSubmit}
                                enableDefaultButtons={true}>
                                    <Field label="Session Date">
                                        <Input type="date" name="session-date" maxLen={100} required={true} changeHandler={setSessionDate} />
                                    </Field>
                                    <Field label="Session Number">
                                        <Input type="text" name="session-number" required={true} changeHandler={setSessionNumber} />
                                    </Field>
                                    <Field label="Session Notes">
                                    <ReactQuill 
                                        theme='snow'
                                        onChange={setSessionNotes}
                                        value={sessionNotes}
                                        placeholder='Session Notes'
                                    />
                                    </Field>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            {redirect !== null &&
                <Redirect to={redirect}></Redirect>
            }
        </>

    )
}

export default AddNote;
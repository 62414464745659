import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import SidebarLink from '../SidebarLink';
import { AuthContext } from '../../FirebaseAuth';

const AccountMenu = () => {

    const { clientId } = useParams();

    const { userData } = useContext(AuthContext);

    useEffect(() => {
        document.querySelectorAll('.c-sidebar').forEach(element => {
            window.coreui.Sidebar._sidebarInterface(element)
        });
    })

    return (

            <ul className="c-sidebar-nav ps ps--active-y">
                <li className="c-sidebar-nav-title">Account</li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/client/'+clientId+'/'?" active":"")} to={'/client/'+clientId+'/'}>
                        <i className="c-sidebar-nav-icon fa fa-tachometer-alt"></i>Overview
                    </SidebarLink>
                </li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/client/'+clientId+'/notes'?" active":"")} to={'/client/'+clientId+'/notes'}>
                        <i className="c-sidebar-nav-icon far fa-clipboard"></i>Session Notes
                    </SidebarLink>
                </li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/client/'+clientId+'/videos'?" active":"")} to={'/client/'+clientId+'/videos'}>
                        <i className="c-sidebar-nav-icon fas fa-film"></i>Video Library
                    </SidebarLink>
                </li>
                {userData.currentClient.role === 'admin' && 
                <>
                    <li className="c-sidebar-nav-title">Settings</li>
                    <li className="c-sidebar-nav-item">
                        <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/client/'+clientId+'/profile')?" active":"")} to={'/client/'+clientId+'/profile'}>
                            <i className="c-sidebar-nav-icon fa fa-user"></i>Profile
                        </SidebarLink>
                    </li>
                    <li className="c-sidebar-nav-item">
                        <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/client/'+clientId+'/billing')?" active":"")} to={'/client/'+clientId+'/billing'}>
                            <i className="c-sidebar-nav-icon fa fa-file-invoice-dollar"></i>Billing
                        </SidebarLink>
                    </li>
                </>
                }
            </ul>
    )
}

export default AccountMenu;
import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { waitForDomChange } from "@testing-library/dom";

const VideoList = () => {
    const title = "Workout Videos"
    const { userData, authUser } = useContext(AuthContext);

    // document snapshots
    const pageSize = 8;
    const [qs, setQs] = useState(null);
    const mountedRef = useRef(true);

    const [rows, setRows] = useState([]);
    const [videos, setVideos] = useState(null);
    const [toEnd, setToEnd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [videoFilter, setVideoFilter] = useState('All');

    const testFunc = (e) => {
        console.log(e.target.value);
        if (e.target.value === 'All') {
            setRows(rows => videos)
        }
        else {
            setRows(rows => videos.filter(row => row.type === e.target.value))
        }
        //setVideoFilter(e.target.value);
        //mountedRef.current = true;
    }
    const getVideos = (pageSize, videoFilter, lastDoc) => {
        setLoading(true);
        let records = [];
        const collectionRef = FirebaseAuth.firestore().collection('videos');
        let query = collectionRef.orderBy('date', 'desc');
        if (videoFilter !== 'All') {
            query = query.where('type','==',videoFilter);
        }
        if(lastDoc){
            query = query.startAfter(lastDoc);
        }
        query = query.limit(pageSize);
        query.get().then(documentSnapshots => {
            console.log('running a q')
            console.log(mountedRef.current)
            if (!mountedRef.current) return null
            if(documentSnapshots.empty){
                setToEnd(true);
            }else{
                documentSnapshots.forEach(doc => {
                    records.push({
                        'id': doc.id,
                        'type': doc.data().type,
                        'code': doc.data().code,
                    });
                });
                if(records.length > 0){
                    setRows(rows => rows.concat(records));
                    setVideos(records);
                    setQs(documentSnapshots);
                }
            }
            setLoading(false);
        });
    };
    useEffect(() => {
        getVideos(pageSize, videoFilter);
        return () => { 
            mountedRef.current = false
        }
    },[videoFilter]);
    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="text-right mb-3">
                        {true &&
                            <>
                                <Link to={"/videos/add"} className="btn btn-primary"><i className="fa fa-plus"></i> Add Video</Link>
                            </>
                        }
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {title}
                        </div>
                        <div className="card-body">
                            <div className="btn-group btn-group-toggle mb-2" >
                                <label className="btn btn-secondary focus active">
                                    <input 
                                        type="radio" 
                                        name="filters"
                                        value="All" 
                                        checked={videoFilter==="All"} 
                                        onChange={testFunc}/>All
                                </label>
                                <label className="btn btn-secondary">
                                    <input 
                                        type="radio" 
                                        name="filters"
                                        value="Core Stability" 
                                        checked={videoFilter==="Core Stability"} 
                                        onChange={testFunc}/>Core Stability
                                </label>
                                <label className="btn btn-secondary">
                                <input 
                                        type="radio"
                                        name="filters" 
                                        value="Muscle Works" 
                                        checked={videoFilter==="Muscle Works"} 
                                        onChange={testFunc}/>Muscle Works
                                </label>
                            </div>
                            {rows.length > 0 &&
                                <>
                                    <div className="container">
                                        {rows.map((r,i) => 
                                            <div className='row pb-2' key={r.id}><iframe width="800" height="450" src={"https://www.youtube.com/embed/"+r.code} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                        )}
                                    </div>
                                </>
                            }
                            {loading?(
                                <Loader text="Loading data..."></Loader>
                            ):(
                                <>
                                
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoList;
import React, { useEffect, useContext } from "react";
import SidebarLink from '../SidebarLink';
import { AuthContext } from "../../FirebaseAuth";

const AppMenu = () => {
    const { userData } = useContext(AuthContext);
    useEffect(() => {
        document.querySelectorAll('.c-sidebar').forEach(element => {
            window.coreui.Sidebar._sidebarInterface(element)
        });
    })

    return (

            <ul className="c-sidebar-nav ps ps--active-y">
                { userData.role === 'admin' && 
                <>
                    <li className="c-sidebar-nav-title">Application</li>
                    <li className="c-sidebar-nav-item">
                        <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/'?" active":"")} to="/">
                            <i className="c-sidebar-nav-icon fa fa-users"></i>Dashboard
                        </SidebarLink>
                    </li>
                    <li className="c-sidebar-nav-item">
                        <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/clients'?" active":"")} to="/clients">
                            <i className="c-sidebar-nav-icon fa fa-book"></i>Clients
                        </SidebarLink>
                    </li>
                    <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname==='/videos'?" active":"")} to="/videos">
                        <i className="c-sidebar-nav-icon fa fa-book"></i>Manage Videos
                    </SidebarLink>
                    </li>
                </>
                }
                <li className="c-sidebar-nav-title">User</li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/user/profile')?" active":"")} to="/user/profile">
                        <i className="c-sidebar-nav-icon fa fa-user"></i> Profile
                    </SidebarLink>
                </li>
                <li className="c-sidebar-nav-item">
                    <SidebarLink className={"c-sidebar-nav-link"+(window.location.pathname.startsWith('/user/log')?" active":"")} to="/user/log">
                        <i className="c-sidebar-nav-icon fa fa-list"></i> Activity Logs
                    </SidebarLink>
                </li>
            </ul>

    )
}

export default AppMenu;
import React, { useContext, useState, useEffect, useRef } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { Link } from "react-router-dom";
import Loader from "../../../../components/Loader";

const NoteList = () => {
    const title = 'Session Notes';

    const { userData, authUser } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    // document snapshots
    const pageSize = 5;
    const [qs, setQs] = useState(null);
    const mountedRef = useRef(true);

    const [rows, setRows] = useState([]);
    const [toEnd, setToEnd] = useState(false);
    const [loading, setLoading] = useState(false);

    const getNotes = (clientId, pageSize, lastDoc) => {
        setLoading(true);
        let records = [];
        const collectionRef = FirebaseAuth.firestore().collection('clients').doc(clientId).collection('notes');
        let query = collectionRef.orderBy('created', 'desc');
        if(lastDoc){
            query = query.startAfter(lastDoc);
        }
        query = query.limit(pageSize);
        query.get().then(documentSnapshots => {
            console.log(documentSnapshots);
            if (!mountedRef.current) return null
            if(documentSnapshots.empty){
                setToEnd(true);
            }else{
                documentSnapshots.forEach(doc => {
                    console.log(doc)
                    records.push({
                        'id': doc.id,
                        'sessionDate': doc.data().sessionDate,
                        'sessionNumber': doc.data().sessionNumber,
                        'sessionNotes': doc.data().sessionNotes
                    });
                });
                if(records.length > 0){
                    setRows(rows => rows.concat(records));
                    setQs(documentSnapshots);
                }
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/client/"+userData.currentClient.id+"/",
                text: userData.currentClient.name,
                active: false
            },
            {
                to: null,
                text: "Notes",
                active: true
            }
        ]);
        getNotes(userData.currentClient.id, pageSize);
        return () => { 
            mountedRef.current = false
        }
    },[userData, setBreadcrumb]);


    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="text-right mb-3">
                        {userData.currentClient.owner === authUser.user.uid &&
                            <>
                                <Link to={"/client/"+userData.currentClient.id+"/notes/add"} className="btn btn-primary"><i className="fa fa-plus"></i> Add Session Note</Link>
                            </>
                        }
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {title}
                        </div>
                        <div className="card-body">
                            {rows.length > 0 &&
                                <>
                                    <table className="table table-responsive-sm table-hover table-outline">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Session Date</th>
                                                <th scope="col">Session Number</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {rows.map((r,i) => 
                                            <tr key={r.id}>
                                                <td><a className="btn btn-link" rel="noreferrer" href={r.hostedInvoiceUrl} target="_blank">{r.sessionDate}</a></td>
                                                <td>{r.sessionNumber}</td>
                                                <td><Link to={"/client/"+userData.currentClient.id+"/notes/"+r.id} className="btn btn-primary">View Note</Link>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </>
                            }
                            {loading?(
                                <Loader text="Loading data..."></Loader>
                            ):(
                                <>
                                {toEnd?(
                                    <span>End of all notes</span>
                                ):(
                                    <button className="btn btn-primary" onClick={e => {
                                        getNotes(userData.currentClient.id, pageSize, qs.docs[qs.docs.length-1]);
                                    }}>View More</button>
                                )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoteList;
import React, { useContext, useState, useEffect, useRef } from "react";
import ReactQuill from 'react-quill';
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { FirebaseAuth } from "../../../../components/FirebaseAuth/firebase";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";

const NoteView = () => {
    const title = 'Session Note';

    const { userData, authUser } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const mountedRef = useRef(true);
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [noteDate, setNoteDate] = useState(null);

    const getNote = (clientId, noteId) => {
        setLoading(true);
        
        FirebaseAuth.firestore().collection('clients').doc(clientId).collection('notes').doc(noteId).get()
        .then(noteRef => {
            setLoading(false);
            setNoteDate(noteRef.data().sessionDate);
            setNote(noteRef.data().sessionNotes);
        });
        
    }

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/client/"+userData.currentClient.id+"/",
                text: userData.currentClient.name,
                active: false
            },
            {
                to: "/client/"+userData.currentClient.id+"/notes",
                text: "Notes",
                active: true
            }
        ]);
        getNote(userData.currentClient.id, params.noteId);
        return () => { 
            mountedRef.current = false
        }
    },[userData, setBreadcrumb, setNote, params.noteId]);


    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="text-right mb-3">
                        {userData.currentClient.owner === authUser.user.uid &&
                            <>
                                <Link to={"/client/"+userData.currentClient.id+"/notes/delete"} className="btn btn-primary"><i className="fa fa-minus"></i> Delete Note</Link>
                            </>
                        }
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {title +' - '+noteDate}
                        </div>
                        <div className="card-body">
                            {loading?(
                                <Loader text="Loading data..."></Loader>
                            ):(
                                <ReactQuill
                                    value={note}
                                    readOnly={true}
                                    theme={"bubble"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoteView;